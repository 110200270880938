<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import appConfig from "@/app.config";

/**
 * Projective Question Guide
 */
export default {
  page: {
    title: "Guide für projektive Fragen",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Guide für projektive Fragen",
      items: [
        {
          text: "Dashbaord",
          href: "/"
        },
        {
          text: "Guides",
          href: "/guides"
        },
        {
          text: "Projective Question Guides",
          active: true
        }
      ]
    };
  },
  methods: {
    clickOnUploadGuide() {}
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted">
              <!-- Overview -->
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-1-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Übersicht</span>
                </template>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <h6>Fragebogen Design - Integrierung projektiver Fragen</h6>
                    <p>
                      Projektive Fragen sind ein wichtiger Teil der Redem
                      Technologie. Bei einer projektiven Frage, geht es um die
                      Einschätzung eines Befragten, wie andere ein Frage
                      beantworten werden. In dieser Anleitung finden Sie alle
                      wichtigen Informationen zur Formulierung und
                      Funktionsweisen der projektiven Fragen.
                    </p>
                  </div>
                  <div class="col-md-12 mt-3">
                    <h6>
                      Allgemeines was Sie über projektive Fragen wissen müssen.
                    </h6>
                    <ul>
                      <li>
                        Bei den Projektionen geht es um eine grobe Schätzung der
                        Befragten über die Antworten von anderen Befragten.
                      </li>
                      <li>
                        Die Projektion darf auf maximal 1 Frage bezogen sein.
                        Dadurch ist die projektive Frage als Zusatzfrage zu
                        sehen.
                      </li>
                      <li>
                        Die “Bezugsfrage” muss eine Single-Choice Frage sein.
                        Fragen mit Mehrfachauswahl werden derzeit nicht
                        unterstützt.
                      </li>
                      <li>
                        Die Formulierung der projektiven Fragen kann beliebig
                        angepasst werden, solange die Erfordernisse der
                        einzelnen Projektions-Typen eingehalten werden.
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-12 mt-3">
                    <h6>Projektions-Typen</h6>
                    <p>
                      Wir unterscheiden nach drei verschiedenen
                      “Projektions-Typen” bei einer projektiven Frage. Je nach
                      Anwendungsfall ist einer dieser Projektions-Typen zu
                      wählen.
                    </p>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-11
                              class="text-dark"
                              href="javascript: void(0);"
                              >Vollständig</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-11"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Die Befragten geben an, wie sich die Antworten
                                  der anderen auf die unterschiedlichen
                                  Antwortoptionen verteilen werden (Antwort 1:
                                  50%, Antwort 2: 20%, Antwort 3: 30%).
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-12
                              class="text-dark"
                              href="javascript: void(0);"
                              >Durchschnitt</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-12"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Die Befragten geben an, wie eine numerische
                                  Skala durschnittlich bzw. mehrheitlich
                                  beantwortet wird.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-13
                              class="text-dark"
                              href="javascript: void(0);"
                              >Übereinstimmung</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-13"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Die Befragten geben an, welcher Prozentsatz
                                  der anderen Befragten mit ihrer gewählten
                                  Antwort übereinstimmen.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- FAQ -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-2-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">FAQ</span>
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-21
                              class="text-dark"
                              href="javascript: void(0);"
                              >Warum werden projektive Fragen benötigt?</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-21"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Der Großteil unserer Algorithmen arbeitet mit
                                  projektiven Angaben. Dabei können vor allem
                                  Verhaltensweisen wie Ehrlichkeit,
                                  Informations-Differenzen zwischen Befragten
                                  oder auch oberflächliches Antwortverhalten
                                  abgeleitet und gemessen werden.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-22
                              class="text-dark"
                              href="javascript: void(0);"
                              >Wie viele projektive Fragen sollen in einen
                              Fragebogen hinzugefügt werden?</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-22"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Wir empfehlen je nach Fragebogenlänge, 3-5
                                  projektive Fragen in einen Fragebogen zu
                                  integrieren. Eine Möglichkeit ist zum Beispiel
                                  - 1 Frage am Anfang, 1 Frage in der Mitte und
                                  1 Frage am Ende der Umfrage. Bei
                                  Mehrthemenbefragungen, sollten Sie mindestens
                                  1 projektive Frage für jedes Thema hinzufügen.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-23
                              class="text-dark"
                              href="javascript: void(0);"
                              >Kann ich Redem auch ohne projektive Fragen
                              verwenden?</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-23"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Derzeit muss jeder Fragebogen mindestens 1
                                  projektive Frage enthalten um die Software zu
                                  verwenden. Zukünftig wird es aber möglich
                                  sein, dass Teile der Redem-Technologie auch
                                  ohne projektive Fragen auskommen.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-24
                              class="text-dark"
                              href="javascript: void(0);"
                              >Auf WEN oder WAS muss sich die projektive Frage
                              beziehen?</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-24"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Die Projektion muss sich immer auf eine klar
                                  definierte Zielgruppe beziehen, wie z.B. die
                                  österreichische Bevölkerung. Damit wird
                                  sichergestellt, dass alle Befragten, sich bei
                                  Ihrer Projektion auf die gleiche Population
                                  beziehen.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-25
                              class="text-dark"
                              href="javascript: void(0);"
                              >An welche Fragen sollte eine projektive Frage
                              angehängt werden</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-25"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Projektive Fragen sollten vor allem an
                                  wichtige Kernfragen angehängt werden, die
                                  inhaltlich am relevantesten sind - zum
                                  Beispiel NPS bei Kundenbefragungen.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" role="tab">
                          <h6 class="m-0">
                            <a
                              v-b-toggle.accordion-26
                              class="text-dark"
                              href="javascript: void(0);"
                              >Bei welchen Fragen kann eine projektive Frage
                              nicht angehängt werden?</a
                            >
                          </h6>
                        </b-card-header>
                        <b-collapse
                          id="accordion-26"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text>
                              <ul>
                                <li>
                                  Eine projektive Frage kann beispielsweise
                                  nicht an eine demographische Abfrage angehängt
                                  werden. Auch Fragen, die sich auf Allgemeine
                                  Angaben beziehen, eignen sich nicht als
                                  Bezugsfrage.
                                </li>
                              </ul>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- Detailed Projection-type -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Vollständige Projektion</span
                  >
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <ul>
                      <li>
                        Bei dieser Art der projektiven Frage, muss der Befragte
                        eine vollständige Angabe über die Antwortverteilung der
                        anderen Teilnehmer machen.
                      </li>
                      <li class="mt-3">
                        Die Frage soll folgendermaßen aufgebaut sein
                        <ul>
                          <li>
                            Und wie glauben Sie, stehen { Zielgruppe einsetzen -
                            z.B österr. Bevölkerung } zu dieser Frage? Bitte
                            schätzen Sie die Anteile so, dass sie gemeinsam 100%
                            ergeben!

                            <p class="mt-3">
                              35% Antwort 1 <br />
                              25% Antwort 2 <br />
                              10% Antwort 3 <br />
                              30% Antwort 4
                              <br />
                              ----------------
                              <br />
                              100%
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li class="mt-3">
                        Die Stärke dieses Projektions-Typs ist der Detailgrad
                        der Projektion. Durch die genaue Angabe der
                        Antwortverteilung, können mehrere Qualitätskriterien
                        abgeleitet werden, was zu einem genaueren Ergebnis
                        führt.
                      </li>
                      <li class="mt-3">
                        Der Vollständige Projektions-Typ eignet sich sehr gut
                        für Fragen bis 8 Antwortmöglichkeiten. Bei mehr als 8
                        Projektions-Angaben wird die Aufgabe für die Befragten
                        zu komplex.
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <!-- Average Projection-type -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Durchschnittliche Projektion</span
                  >
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <ul>
                      <li>
                        Bei dieser Art der Projektion geben die Befragten eine
                        Schätzung ab, wie der Durchschnitt beziehungsweise die
                        Mehrheit der anderen Befragten eine Skala beantworten
                        werden.
                      </li>
                      <li class="mt-3">
                        Die Antwortmöglichkeiten der projektiven Frage sind hier
                        ident mit der Bezugsfrage.
                      </li>
                      <li class="mt-3">
                        Aufbau Frage:
                        <ul>
                          <li>
                            Schätzen Sie, wie { Zielgruppe einfügen - z.B.
                            andere Kunden } diese Frage durchschnittlich
                            beantwortet haben!
                            <p class="mt-3">
                              ____ Ja <br />
                              _X__ Eher Ja <br />
                              ____ Eher Nein <br />
                              ____ Nein
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li class="mt-3">
                        Dieser Projektions-Typ wurde speziell für Fragen, mit
                        numerischen Antwortoptionen entwickelt und kann auch nur
                        für diese verwendet werden.
                        <br />
                        z.B. Likert Skalen (Sehr zufrieden - Gar nicht
                        zufrieden, NPS, 0-100 Skala)
                      </li>
                      <li class="mt-3">
                        Der Vorteil dieses Projektions-Typs liegt in der
                        einfachen Anwendung. Es wird lediglich 1 Angabe des
                        Befragten benötigt.
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <!-- Agreement Projection-type -->
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="mdi mdi-numeric-3-circle"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Übereinstimmende Projektion</span
                  >
                </template>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <ul>
                      <li>
                        Bei dieser Art der Projektion geben Befragte eine
                        Schätzung ab, wie viel Prozent der anderen Befragten mit
                        ihrer Antwort übereinstimmen werden
                      </li>
                      <li class="mt-3">
                        Eine Frage sollte folgendermaßen aussehen:
                        <ul>
                          <li>
                            Schätzen Sie, wie viel Prozent der { Zielgruppe
                            einsetzen - z.B. Mitarbeiter im Unternehmen}, mit
                            Ihrer Antwort übereinstimmen werden (0-100%).
                            <p class="mt-2">_55_% <br /></p>
                          </li>
                        </ul>
                      </li>
                      <li class="mt-3">
                        Dieser Projektions-Typ eignet sich gut bei Fragen mit
                        einer hohen Anzahl an kategorischen
                        Antwortmöglichkeiten.
                      </li>
                      <li class="mt-3">
                        Aufgrund des geringen Befragten-Inputs, ist dieser
                        Projektions-Typ jedoch weniger aussagekräftig als
                        beispielsweise der Vollständige Projektions-Typ.
                      </li>
                      <li class="mt-3">
                        Der große Vorteil dieses Projektionstyps liegt jedoch in
                        der sehr einfachen Anwendbarkeit und den breiten
                        Einsatzmöglichkeiten.
                      </li>
                      <li class="mt-3">
                        Beispiele für Projektions-Typ Übereinstimmung
                        <ul>
                          <li>
                            Schätzen Sie, wie viel Prozent der {Zielgruppe
                            einsetzen}, mit Ihrer Antwort übereinstimmen werden
                            (0-100%).
                          </li>
                          <li>
                            Was werden die {Zielgruppe einsetzen} sagen? Wie
                            viel Prozent werden die gleiche Antwort wie Sie
                            wählen (0-100%)?
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
